import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  defaultContext, 
} from '@tanstack/react-query'

import './App.css';

import List from './components/list';
import RetrospectiveEditor from "./components/retrospective-editor";
import UserValidatedRetrospective from "./components/UserValidatedRetrospective";
import { PlatformProvider } from '@labqube/contexts';
import PromptName from "./components/prompt-name";
import Selection from "./components/selection";
import Templates from "./components/templates";

const queryClient = new QueryClient({
  context: defaultContext,
})

function App() {
  return (
    <div>
      <PlatformProvider>
        <QueryClientProvider client={queryClient} context={defaultContext}>
          <HashRouter>
            <Routes>
              <Route path="" element={<Navigate to="retrospectives/list" replace />} />
              <Route
                path="retrospectives/list"
                element={<List />} 
              />
              <Route 
                path="retrospectives/new/selection"
                element={<Selection />}
              />
              <Route 
                path="retrospectives/new/selection/templates"
                element={<Templates />}
              />
              <Route
                path="retrospectives/new"
                element={<RetrospectiveEditor />} 
              />
              <Route
                path="retrospectives/prompt-name"
                element={<PromptName />} 
              />
              <Route
                path="retrospectives/:retrospectiveId/edit"
                element={<RetrospectiveEditor />} 
              />
              <Route
                path="retrospectives/:retrospectiveId"
                element={
                  <UserValidatedRetrospective />
                } 
              />
            </Routes>
          </HashRouter>
        </QueryClientProvider>
      </PlatformProvider>
    </div>
  );
}

export default App;
