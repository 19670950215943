import React , { useContext, useState } from 'react';
import { defaultContext } from '@tanstack/react-query';
import { useParticipant } from '@labqube/hooks';
import ClosingActionItems from './closing-action-items';
import useActionItems from '../../hooks/useActionItems';
import { useRetrospective } from '../../hooks/useRetrospective';
import './closing.css';
import { PlatformContext, WEBEX } from '@labqube/contexts';

const Idea = ({ idea, columns }) => {
  const { data: retrospective } = useRetrospective(idea.retrospective_id);
  const { platform } = useContext(PlatformContext);
  const { data: participant } = useParticipant({ userAccountId: idea.creator_account_id }, defaultContext);
  const column = columns?.find(col => col._id === idea.column_id);
  const borderColor = column ? column.color : '#CCC';

  const retrospectiveParticipant = retrospective.participants.find(p => p.account_id === idea.account_id);

  return (
    <li className="idea" style={{ border: `1px solid ${borderColor}`, padding: '10px', margin: '10px 0', display: 'flex' }}>
      <img
        src={platform === WEBEX ? 'https://ui-avatars.com/api/?name=' + retrospectiveParticipant.name + '&size=24' : participant?.avatarUrls?.['24x24']}
        alt="Avatar"
        style={{
          height: '24px',
          width: '24px',
          borderRadius: '50%',
          marginRight: '10px',
        }}
      />
      <span style={{ textAlign: 'left'}}>{idea.content}</span>
    </li>
  );
};

const Closing = ({ retrospectiveId, groups, ideasByGroup }) => {

  const { data } = useRetrospective(retrospectiveId);
  const { me } = data || {};

  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);

  const groupsWithIdeas = groups.filter(group => (ideasByGroup[group._id] || []).length > 0);

  // Crear un nuevo array que contenga solo las ideas no agrupadas
  const ungroupedIdeas = ideasByGroup['undefined'] || [];

  // Solo agregar las ideas no agrupadas al final de los grupos si hay ideas no agrupadas
  const allGroups = ungroupedIdeas.length > 0
    ? [...groupsWithIdeas, { _id: 'undefined', name: 'Ungrouped Ideas', description: 'These are the ideas that have not been grouped.' }]
    : [...groupsWithIdeas];
  
  const currentGroup = allGroups[currentGroupIndex];

  const { actionItems } = useActionItems(retrospectiveId, currentGroup._id);

  const goToNextGroup = () => {
    const newIndex = (currentGroupIndex + 1) % allGroups.length;
    setCurrentGroupIndex(newIndex);
  };

  const goToPreviousGroup = () => {
    const newIndex = (currentGroupIndex - 1 + allGroups.length) % allGroups.length;
    setCurrentGroupIndex(newIndex);
  };

  return (
    <div className='closing-wrapper'>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h3 style={{ wordBreak: 'break-word' }}>{ currentGroup.name }</h3>
      </div>
      {me.is_moderator && (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button onClick={goToPreviousGroup} style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}><i className="fas fa-arrow-left"></i></button>
            <span>{allGroups.findIndex(group => group._id === currentGroup._id) + 1} of {allGroups.length}</span>
            <button onClick={goToNextGroup} style={{ backgroundColor: 'transparent', border: 'none', cursor: 'pointer' }}><i className="fas fa-arrow-right"></i></button>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '45%', textAlign: 'center', padding: '20px' }}>
          <div>
            {allGroups.map((group, index) => (
              <div key={index} style={{ display: currentGroup._id === group._id ? 'block' : 'none' }}>

                {/* Aquí se muestran las ideas para el grupo actual */}
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                  {(ideasByGroup[group._id] || []).map((idea, ideaIndex) => (
                    <Idea key={ideaIndex} idea={idea} columns={data.columns}/>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        <ClosingActionItems actionItems={actionItems || []} />
      </div>
    </div>
  );
};

export default Closing;
