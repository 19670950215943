import React from 'react';
import PropTypes from 'prop-types';
import { Banner } from '@labqube/components';
import Columns from '../columns';

const Ideation = ({ columns, ideasByColumn }) => {
    return (
        <>
            <div style={{ width: '100%' }}>
                <Banner
                    title='Write your ideas below'
                    appearance={'INFO'}
                    textAlign='center'
                />
            </div>
            {columns && <Columns stage={'ideation'} columns={columns} ideasByColumn={ideasByColumn} />}
        </>
    );
};

Ideation.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    })).isRequired
};

export default Ideation;
