import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Loading, Button, PageHeader } from '@labqube/components';
import useRetrospectiveTemplates from "../hooks/useRetrospectiveTemplates";
import './templates.css';

const Templates = () => {

    const navigate = useNavigate();

    const { isLoading, data } = useRetrospectiveTemplates();

    if (isLoading) {
        return <Loading />; 
    };

    const handleBack = () => {
        navigate('/retrospectives/new/selection');
    };

    return (
        <div style={{ padding: 41, paddingTop: 0 }}>
            <PageHeader
                title='Templates'
                breadcrumbs={[{
                    href: <Link to={'/'}>Retrospectives</Link>
                }, {
                    href: '',
                    text: 'Selection'
                }]}
                action={
                    <Button type={'default'} onClick={handleBack} style={{ height: 37, minWidth: 120 }}>
                        Back
                    </Button>
                }
            />
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                {data?.map(template => (
                    <div key={template._id} style={{ flex: '0 0 calc(33.33% - 40px)', border: '2px solid #ccc', borderRadius: 10, padding: 10 }}>
                        <div style={{ textAlign: 'center' }}>
                            <h3 className="text" style={{ minHeight: 30 }}>{template.name}</h3>
                            <p className="text" style={{ minHeight: 45 }}>{template.description}</p>
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                {template.columns.map((column, index) => (
                                    <div key={index} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 30 }}>
                                        <span className="text">{column.name}</span>
                                        <div style={{ backgroundColor: column.color, width: 40, height: 40, marginTop: 5 }}></div>
                                    </div>
                                ))}
                            </div>
                            <Link to={`/retrospectives/new?templateId=${template._id}`} style={{ textDecoration: 'none', color: 'black', marginBottom: 20 }}>
                                <Button type={'primary'} style={{ height: 35, width: 120 }}>
                                    Use template
                                </Button>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Templates;