import { Input } from '@labqube/components';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRetrospective } from '../hooks/useRetrospective';
import Idea from './idea';

function Columns({ stage, columns, ideasByColumn }) {

    const { retrospectiveId } = useParams();
    const { createIdea, deleteIdea } = useRetrospective(retrospectiveId);
    const [text, setText] = useState({});

    const handleKeyDown = (columnId) => async (event) => {
        if (event.key === 'Enter') {
            await createIdea(retrospectiveId, columnId, text[columnId]);
            setText({ ...text, [columnId]: '' });
        }
    };

    const handleDelete = (idea) => {
        deleteIdea(idea._id);
    }

    const handleEdit = async (idea) => {
        // Use async/await to handle the promise returned by deleteIdea
        const deleteSuccess = await deleteIdea(idea._id);
        if (deleteSuccess) {
            setText({ ...text, [idea.column_id]: idea.content });

            // Use window.document to focus the input
            const inputElement = window.document.getElementById(`input-${idea.column_id}`);
            if (inputElement) {
                inputElement.focus();
            }
        }
    };

    const renderIdeation = () => {
        return (
            <>
                <div>
                    <div style={{ display: 'flex' }}>
                        {columns.map((column, index) => (
                            <div key={column._id} style={{ flex: 1, minWidth: '250px', maxWidth: '400px', marginRight: index < columns.length - 1 ? 15 : 0 }}>
                                <p style={{ textAlign: 'center', wordBreak: 'break-word' }}>{column.name}</p>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex' }}>
                        {columns.map((column, index) => (
                            <div key={column._id} style={{ flex: 1, minWidth: '250px', maxWidth: '400px', marginRight: index < columns.length - 1 ? 15 : 0 }}>
                                <Input
                                    id={`input-${column._id}`}
                                    value={text[column._id] || ''}
                                    placeholder="Add an idea"
                                    onChange={(e) => {
                                        setText({ ...text, [column._id]: e.target.value })
                                    }}
                                    onKeyDown={handleKeyDown(column._id)}
                                    style={{ marginBottom: 10 }}
                                />
                                {ideasByColumn[column._id]?.map((idea) => (
                                    <Idea key={idea._id} idea={idea} stage={stage} onDelete={handleDelete} onEdit={handleEdit} color={column.color}/>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </>
        );
    }

    const renderGrouping = () => {
        return (
            <>
                <div style={{ flex: 1, display: 'flex', width: '100%', justifyContent: 'left' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {columns.map((column) => (
                            ideasByColumn[column._id]?.map((idea) => (
                                <div key={idea.id} style={{ marginTop: 10 }}>
                                    <Idea idea={idea} stage={stage} color={column.color}/>
                                </div>
                            ))
                        ))}
                    </div>
                </div>
            </>
        );
    }; 
    
    return (
        <div style={{ display: 'flex', justifyContent: stage === 'ideation' ? 'center' : 'flex-start', width: '100%' }}>
            {stage === 'ideation' ? renderIdeation() : renderGrouping()}
        </div>
    );
}

export default Columns;
