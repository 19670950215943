import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { defaultContext } from '@tanstack/react-query';
import { useCurrentPlatformUser, useParticipant } from '@labqube/hooks';
import './idea.css';
import { useRetrospective } from '../hooks/useRetrospective';
import { PlatformContext, WEBEX } from '@labqube/contexts';

const Idea = ({ idea, stage, onDelete, onEdit, color }) => {

  const { data: retrospective } = useRetrospective(idea.retrospective_id);
  const { platform } = useContext(PlatformContext);
  const { data: participant } = useParticipant({ userAccountId: idea.creator_account_id }, defaultContext);
  const { accountId: currentAccountId } = useCurrentPlatformUser(defaultContext);
  const [isHovered, setIsHovered] = useState(false);

  const retrospectiveParticipant = retrospective.participants.find(p => p.account_id === idea.account_id);

  return (
    <div
      className='idea'
      style={{
        position: 'relative',
        background: `linear-gradient(to right, ${color}, ${color} 10px, white 10px, white 100%)`,
        color: 'black',
        padding: '10px',
        paddingLeft: 20,
        marginBottom: '5px',
        borderRadius: '5px',
        border: `1px solid ${color}`,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <img
          src={platform === WEBEX ? 'https://ui-avatars.com/api/?name=' + retrospectiveParticipant.name + '&size=24' : participant?.avatarUrls?.['24x24']}
          alt="Avatar"
          style={{
            height: '24px',
            width: '24px',
            borderRadius: '50%', // para hacer la imagen circular
            marginRight: '10px', // separación con el contenido
          }}
        />
        <p style={{ flex: 1, marginRight: '10px', marginTop: 0, wordBreak: 'break-word', textAlign: 'left' }}>
          {idea.content}
        </p>
          {
            isHovered && idea.account_id === currentAccountId && stage === 'ideation' && (
              <>
                <div style={{ display: 'flex' }}>
                  <i
                    className="fas fa-pencil-alt"
                    onClick={() => onEdit ? onEdit(idea) : null}
                    style={{
                      marginLeft: 'auto',
                      color: 'black',
                      cursor: 'pointer',
                      marginRight: '10px', // separación con el botón de eliminar
                    }}
                  />
                  <i
                    className="fas fa-trash"
                    onClick={() => onDelete ? onDelete(idea) : null}
                    style={{
                      color: 'black',
                      cursor: 'pointer'
                    }}
                  />
                </div>
              </>
            )
          }
      </div>
    </div >
  );
};

Idea.propTypes = {
  idea: PropTypes.shape({
    content: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default Idea;